import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  message,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Tooltip,
  Spin,
  Typography,
  Modal,
  InputNumber,
  Divider,
  Alert,
} from 'antd';
import { useWindowDimensions } from '../../utils/hooks';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import { ColumnsType } from 'antd/lib/table';
import { SearchOutlined, EyeOutlined, PlusOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
const server = process.env.REACT_APP_SERVER_URL;

const RegisterCondomium: React.FC<RegisterCondomiumProps> = ({ thm, auth }) => {
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [condominiums, setCondominiums] = useState<Condomium[]>([]);
  const [tipoDocumento, setTipoDocumento] = useState('J');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const inputRef = useRef<any>(null);
  const [modalForm, setModalForm] = useState(false);
  const [selectEdificio, setSelectEdificio] = useState<'EDIFICIO' | 'CASA' | null>(null);

  const history = useHistory();
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={inputRef}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (inputRef.current) {
          inputRef.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<Condomium> = [
    {
      title: 'Razón Social',
      dataIndex: 'razonSocial',
      ...getColumnSearchProps('razonSocial'),
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
    },
    {
      title: 'Acciones',
      dataIndex: 'idCondominio',
      render: (id) => (
        <>
          {!deleting ? (
            <span>
              <Tooltip title='Ver Propietarios'>
                <Button
                  type='link'
                  icon={<EyeOutlined />}
                  onClick={() => history.push(`./ver/${id}`)}
                  style={{ marginRight: 8, border: 'none' }}
                />
              </Tooltip>
              <Tooltip title='Eliminar Condominio'>
                <Button type='link' icon={<DeleteOutlined />} onClick={() => deleteCondominium(id)} style={{ border: 'none' }} />
              </Tooltip>
            </span>
          ) : (
            <Spin indicator={<LoadingOutlined />} style={{ marginLeft: 20 }} />
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchCondominiums();
    // eslint-disable-next-line
  }, []);

  const fetchCondominiums = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/condominium/`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setCondominiums(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al obtener condominios');
    } finally {
      setLoading(false);
    }
  };

  const addCondominium = async () => {
    const values = await form.validateFields();
    if (!tipoDocumento) return message.error('Debe incluir el tipo de documento');
    setCreating(true);
    try {
      const response = await axios.post(
        `${server}/condominium/`,
        { ...values, tipoDocumento, esEdificio: selectEdificio === 'EDIFICIO' },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Condominio registrado de manera exitosa');
      setCondominiums([response.data.condominio, ...condominiums]);
      setModalForm(false);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al agregar condominio');
    } finally {
      setCreating(false);
    }
  };

  const deleteCondominium = async (id: number) => {
    setDeleting(true);
    try {
      const response = await axios.delete(`${server}/condominium/${id}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Condominio eliminado de manera exitosa');
      const _condominiums = condominiums.filter((c) => c.idCondominio !== id);
      setCondominiums(_condominiums);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al eliminar condominio');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Condominios'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 1200 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Row gutter={[24, 24]}>
        <Modal
          visible={modalForm}
          onCancel={() => setModalForm(false)}
          onOk={() => addCondominium()}
          centered
          title='Registro de condominio'
          width={'100vh'}
          bodyStyle={{ padding: '30px 50px', height: '70vh', overflowY: 'scroll' }}
          confirmLoading={creating}
          maskClosable
        >
          <Form layout='vertical' form={form}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Alert
                  message='Para registrar la figura del condominio. El conjunto residencial debe hacer un registro previo como contribuyente e ingresarlo a continuación'
                  type='info'
                />
              </Col>
              <Col xl={8} xs={24}>
                <Form.Item
                  name='documento'
                  label='Documento de Identidad'
                  rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
                >
                  <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation='left' />

            <Row gutter={[24, 24]}>
              <Col xl={8} xs={24}>
                <Form.Item name='esEdificio' label='Es edificio' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Select onChange={(e: any) => setSelectEdificio(e)}>
                    <Select.Option value={'EDIFICIO'}>Si</Select.Option>
                    <Select.Option value={'CASA'}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {selectEdificio === 'EDIFICIO' && (
              <Row gutter={[24, 24]}>
                <Col xl={12} xs={24}>
                  <Form.Item
                    name='apartamentos'
                    label='Cantidad de apartamentos'
                    rules={[{ required: true, message: 'Esta campo es requerido' }]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder='Cantidad de apartamentos' />
                  </Form.Item>
                </Col>
                <Col xl={12} xs={24}>
                  <Form.Item
                    name='cantidadPisos'
                    label='Cantidad de pisos'
                    rules={[{ required: true, message: 'Esta campo es requerido' }]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder='Cantidad de pisos' />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {selectEdificio === 'CASA' && (
              <Row gutter={[24, 24]}>
                <Col xl={12} xs={24}>
                  <Form.Item
                    name='cantidadCasas'
                    label='Cantidad de casas'
                    rules={[{ required: true, message: 'Esta campo es requerido' }]}
                  >
                    <InputNumber style={{ width: '100%' }} placeholder='Cantidad de casas' />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Divider orientation='left' />

            <Row gutter={[24, 24]}>
              <Col xl={6} xs={24}>
                <Form.Item name='parroquia' label='Parroquia' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Input maxLength={3} placeholder='Parroquia' />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item name='sector' label='Sector' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Input maxLength={3} placeholder='Sector' />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item name='parcela' label='Parcela' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Input maxLength={3} placeholder='Parcela' />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item name='manzana' label='Manzana' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Input maxLength={3} placeholder='Manzana' />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xl={12} xs={24}>
                <Form.Item
                  name='areaConstruccion'
                  label='Area de construcción'
                  rules={[{ required: true, message: 'Esta campo es requerido' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder='Area de construcción' />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                <Form.Item
                  name='areaTerreno'
                  label='Area de terreno'
                  rules={[{ required: true, message: 'Esta campo es requerido' }]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder='Area de terreno' />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xl={12} xs={24}>
                <Form.Item
                  name='coordenada_x'
                  label='Coordenada x'
                  rules={[{ required: true, message: 'Esta campo es requerido' }]}
                >
                  <Input placeholder='Coordenado x' />
                </Form.Item>
              </Col>
              <Col xl={12} xs={24}>
                <Form.Item
                  name='coordenada_y'
                  label='Coordenada y'
                  rules={[{ required: true, message: 'Esta campo es requerido' }]}
                >
                  <Input placeholder='Coordenada y' />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xl={24} xs={24}>
                <Form.Item name='direccion' label='Dirección' rules={[{ required: true, message: 'Esta campo es requerido' }]}>
                  <Input placeholder='Dirección' />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Col span={24}>
          <Button onClick={() => setModalForm(true)} type='primary' icon={<PlusOutlined />}>
            Registrar Condominio
          </Button>
        </Col>

        <Col span={24}>
          <Typography.Text strong>Total de Condominios registrados: {condominiums?.length || 0}</Typography.Text>
        </Col>

        <Col span={24}>
          <Table loading={loading} columns={columns} dataSource={condominiums} rowKey={({ idCondominio }) => idCondominio} />
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(RegisterCondomium);

interface RegisterCondomiumProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}

interface Condomium {
  idCondominio: number;
  documento: string;
  razonSocial: string;
}
