import axios from 'axios';
import moment from 'moment';
import XLSX from 'xlsx';
const server = process.env.REACT_APP_SERVER_URL;

export const newReportPdf = async (data, token, type = 'RPR') => {
  let response, intervalResponse;
  try {
    const body = {
      from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
      to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      alcaldia: data.alcaldia,
    };

    const id = await axios.post(`${server}/branches/${type}`, body, { headers: { Authorization: `Bearer ${token}` } });
    if (type === 'IDR') return id.data;

    response = await new Promise((res, rej) => {
      const int = setInterval(async () => {
        intervalResponse = await axios.get(`${server}/branches/${id.data.data}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(intervalResponse.data);
        if (intervalResponse.status === 200) {
          res(intervalResponse);
          clearInterval(int);
        }
      }, 30000);
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const clouserAllCash = async (data) => {
  try {
    const response = await axios.post(
      `${server}/cashier/all`,
      {
        from: moment(data.rango[0]).utcOffset('-4').startOf('day').toISOString(true),
        to: moment(data.rango[1]).utcOffset('-4').endOf('day').toISOString(true),
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const closureCash = async (day) => {
  try {
    const response = await axios.post(
      `${server}/cashier`,
      { day },
      { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const closureCashOne = async (data) => {
  try {
    const response = await axios.post(`${server}/cashier/one`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newReportExcel = async (data, token) => {
  try {
    const body = { from: data.rango[0], to: data.rango[1], ramo: data.ramo };
    const response = await axios.post(`${server}/settlements/report`, body, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const newReportExcelIVA = async (data, token) => {
  try {
    const body = { from: data.rango[0], to: data.rango[1], ramo: data.ramo };
    const response = await axios.post(`${server}/settlements/report/iva`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportDAE = async (data, rim, token) => {
  try {
    const response = await axios.get(
      `${server}/definitive-declaration?tipoDocumento=${data.tipoDocumento}&documento=${data.documento}&referencia=${rim}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportTransf = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportTransf`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportTransfBank = async (data, token, bankState) => {
  try {
    if (bankState) {
      const response = await axios.post(`${server}/branches/reportTransfBank/${bankState}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    }
    return { status: 403 };
  } catch (e) {
    throw e;
  }
};

export const reportCondo = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/condoReport`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportCondoDisclosed = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/condoReportDisclosed`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportTimeFunctionary = async (data, token) => {
  try {
    const response = await axios.post(`${server}/reportCPU/timeFunctionary`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    let reportData = response.data.data;

    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(reportData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
    XLSX.writeFile(workbook, 'ReporteTiempoPersonal.xlsx');

    return { status: 201 };
  } catch (error) {
    throw error;
  }
};

export const reportRMP = async (data, token) => {
  try {
    const response = await axios.post(`${server}/report/methodPay`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportRID = async (data, token) => {
  try {
    const response = await axios.post(`${server}/report/ingresadoDetallado`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportTransfExternal = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportTransfExternal`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportAttenPendTickets = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportSupport`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportPayed = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportPayed`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const reportPagosAprobados = async (data, token) => {
  try {
    const response = await axios.post(`${server}/branches/reportPagosAprobados`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
